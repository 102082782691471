.excel{
    padding: 50px 10px;
    width: 100%;
    form {
        margin: 30px auto;
        width: 100%;
    }
    
    .align-button {
        display: flex;
        width: 100%;
    }

    .right{
        right: 30px;
    }

    .left{
        left: 20px;
    }
    
    .button, label {
        margin-bottom: 10px;
        position: absolute;
        padding: 8px;
        background-color: #bf0811;
        color: white;
        border-radius: 5px;
        display: flex;
        text-decoration: none;
        cursor: pointer;
    }
    .download{
        background-color: white;
        border: 1px solid #bf0811;
        color: #bf0811;
        left: 130px;
    }

    input{
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
}