.content-layout{
    padding: 140px 5px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

.inicio{
    padding: 180px 5px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    //max-width: 1300px;

    .banner{
        display: flex;
        width: 100%;
        margin-bottom: 40px;
        img{
            width: 100%;
            width: 100%;
            border-radius: 0px;
            box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
        }
    }
  
    .inicio-categoria{
        padding: 10px;
        overflow-x: auto;
        background-color: #ebebeb;
        border-radius: 20px;
        .item {
            div{
                background-color: #C20139;
                // margin: 10px;
                min-width: 90px;
                min-height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                transform: scale(0.9);
                a{
                    text-align: center;
                    color: white;
                    font-weight: bold;
                    padding: 5px;
                    font-size: 1rem;
                }
            }
            div:hover{
                box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
                transform: scale(1.1);
                a{
                    font-size: 1.2rem;
                }
                
            }
        }
    }  
    .pedido-list{
        padding: 20px 0px !important;
        z-index: 1000000;
    }

    .home-content{
        padding-left:30px;
        padding-right:30px;
        display: flex;
        flex-direction: column-reverse;
        .marca {
            width: 100%;
            .marca-content{
                display: flex;
                flex-direction: row;
                margin-bottom: 20px;
                .item{
                    padding: 0px 5px;
                    p{
                        display: none;
                    }
                }
               
            }   
        }
        .home-seccion{
            width: 100%;
            .momento{
                display: flex;
                margin-top: 20px;
                overflow-x: overlay;
                .momento-item{
                    width: 100%;
                    height: 170px;
                    margin: 5px;
                    border-radius: 20px;
                    div{
                        width: 100%;
                        height: 100%;
                        border-radius: 20px;
                        background-size: 100% 100%;
                        position: relative;
                        min-width: 150px;
                    }
                    
                    .momento-fondo{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        border-radius: 20px;
                        background-color: rgba(0,0,0,0.3);
                    }
                    p{
                        position: absolute;
                        bottom: 10px;
                        left: 20px;
                        color: white;
                        font-weight: bold;
                    }
                }
            }
            // .momento::-webkit-scrollbar {
            //     width:30px;
            // }
            .momento::-webkit-scrollbar * {
                background:transparent;
            }
            .momento::-webkit-scrollbar-thumb {
                background:rgba(255,0,0,0.1) !important;
            }
        }
    }
}

.inicio-titulo{
    margin-top: 20px;
    margin-bottom: 0px;
    h2{
        display: initial;
        border-bottom: 2px solid $primary;
        font-weight: 900;
        color: #616161;
    }
    a{
        margin-left: 20px;
        // background-color: #f64f9d;
        // color: white;
        font-size: 0.9rem;
        padding: 5px 20px;
        border-radius: 15px;
        font-weight: bold;
        img{
            margin-left: 10px;
        }
    }
    a:hover{
        opacity: 0.8;
    }
    button{
        margin-left: 20px;
        // background-color: #f64f9d;
        // color: white;
        font-size: 0.9rem;
        padding: 5px 20px;
        border-radius: 15px;
        font-weight: bold;
        img{
            margin-left: 10px;
        }
    }
    button:hover{
        opacity: 0.8;
    }
}

.seccion{
    padding: 0px 10px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
    .pedido-list {
        padding: 0px 10px !important;
    }
    .home-content{
        display: flex;
        flex-direction: column-reverse;
        .marca {
            width: 100%;
            .marca-content{
                display: flex;
                flex-direction: row;
                margin-bottom: 20px;
                .item{
                    padding: 0px 5px;
                    p{
                        display: none;
                    }
                }
               
            }   
        }
        .home-seccion{
            width: 100%;
            .momento{
                display: flex;
                margin-top: 20px;
                overflow-x: overlay;
                .momento-item{
                    width: 100%;
                    height: 170px;
                    margin: 5px;
                    border-radius: 20px;
                    div{
                        width: 100%;
                        height: 100%;
                        border-radius: 20px;
                        background-size: 100% 100%;
                        position: relative;
                        min-width: 150px;
                    }
                    
                    .momento-fondo{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        border-radius: 20px;
                        background-color: rgba(0,0,0,0.3);
                    }
                    p{
                        position: absolute;
                        bottom: 10px;
                        left: 20px;
                        color: white;
                        font-weight: bold;
                    }
                }
            }
            // .momento::-webkit-scrollbar {
            //     width:30px;
            // }
            .momento::-webkit-scrollbar * {
                background:transparent;
            }
            .momento::-webkit-scrollbar-thumb {
                background:rgba(255,0,0,0.1) !important;
            }
        }
    }
}

.descargaapp{
    width: 100%;
    display: flex;
    img{
        width: 100%;
        // border-radius: 20px;
        box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
    }
}

.img-banner{
    max-width: 100%;
    height: 310px;
    background-color: white;
    object-fit: contain;
}

.carousel-banner{
    height: 310px;
    width: 100%;
    background-color: white;
}

.button-banner{
    position: absolute;
        top: calc(50% - 15px);
        width: 30;
        height: 30;
        cursor: pointer;
}

@media only screen and (min-width: $device-sm) {
    .inicio{
        padding: 140px 0px;
    }
    
}

@media only screen and (min-width: $device-md) {
    .inicio{
        padding: 140px 0px;
    }
    .seccion{
        padding: 10px 20px;
    }

    .home-content{
        padding-left:10px;
        padding-right:10px;
        flex-direction: row !important;
        display: flex;
        .marca {
            width: 20% !important;
            .marca-content{
                display: flex !important;
                flex-direction: column !important;
                margin-bottom: initial !important;
                .item{
                    padding: 5px 0px !important;
                    p{
                        display: initial !important;
                    }
                }
            } 
        }
        .home-seccion{
            width: 80% !important;
            .momento{
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-auto-rows: auto;
                grid-gap: 1rem;
            }
        }
    }
}

@media only screen and (min-width: $device-lg) {
    .content-layout{
        padding: 40px 5px;
    }
    .home-content{
        flex-direction: row !important;
        padding-left:10px;
        padding-right:10px;
        .marca {
            width: 15% !important;
            .marca-content{
                display: flex !important;
                flex-direction: column !important;
                margin-bottom: initial !important;
                .item{
                    padding: 5px 0px !important;
                    p{
                        display: initial !important;
                    }
                }
            } 
        }
        .home-seccion{
            width: 85% !important;
            .momento{
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                grid-auto-rows: auto;
                grid-gap: 1rem;
            }
        }
    }
}
