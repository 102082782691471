.footer {
    background-color: $primary;
    width: 100%;
    min-height: 250px;
    padding-left: 20px;
    padding-right: 20px;
    .content{
        max-width: 1200px;
        display: flex;
        align-items: center;   
        justify-content: center;
        flex-direction: column;
        .logo{
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .img-logo{
                img{
                    width: auto;
                    height: 90px;
                }
            }
            .download-app{
                display: flex;
                align-items: center;
                flex-direction: row;
                div{
                    width: initial !important;
                    height: initial !important;
                    display: flex;
                    align-items: center;
                    a{
                        width: 100px !important;
                        height: 34px !important;
                        padding: 0px !important;
                        margin: 0px !important;
                    }
                }
                .android{
                    a{
                        height: 47px !important;
                        width: 110px !important;
                    }
                }
                // img{
                //     width: auto;
                //     height: 30px;
                // }
            }
        }
        .links{
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            margin-top: 20px;
            ul {
                li {
                    padding: 2px;
                    a{
                        color: white;
                        font-size: 0.85rem;
                    }
                }
            }
        }
        .redes{
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            margin-top: 40px;
            margin-bottom: 60px;
            p {
                color: white;
                font-size: 0.85rem;
            }
            .icon-redes{
                ul {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                    a {
                        width: 35px;
                        height: 35px;
                        border: 2px solid white;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $device-sm) {
   
}

@media only screen and (min-width: $device-md) {
    .footer {
        .content{
            flex-direction: row;
            .logo {
                width: 33%;
                justify-content: initial;
                align-items: flex-start;
            }
            .links{
                width: 33%;
                text-align: left;
                margin-top: 0px;
            }
            .redes{
                width: 33%;
                justify-content: flex-end;
                text-align: left;
                margin-top: 0px;
                margin-bottom: 0px;
                .icon-redes{
                    ul {
                        justify-content: left;
                    }
                }
            }
        }
    }
}