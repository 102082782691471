.login {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .login-content {
        .splash {
            background-color: #df513e00;
            width: 100%;
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            display: none;
            // animation-name: showform;
            // animation-duration: 1s;
            .splash-titulo {
                margin-left: 20%;
                margin-right: 20%;
                h3 {
                    color: white;
                    text-align: center;
                    margin: 10%;
                }
            }
            .logo-img {
                height: 15%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                animation-name: bounceInLeft;
                animation-duration: 1s;
                img {
                    width: auto;
                    height: 80px;
                    z-index: 1;
                }
            }
            .maqueta-img {
                height: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                animation-name: bounceInDown;
                animation-duration: 1s;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .slide-footer{
                height: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                animation-name: bounceInRight;
                animation-duration: 1s;
                .footer-img{
                    margin-top: 20px;
                    text-align: center;
                    img {
                        height: 100%;
                        width: 100%;
                    }
                }
            } 
            .splash-link{
                width: 100%;
                text-align: center;
                a {
                    color: white;
                    font-weight: bold;
                    border-bottom: 1px solid white;
                }
            }
        }
        .login-form {
            // display: none;
            display: flex;
            width: 100%;
            height: 100vh;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            position: relative;
            .main{
                width: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow: auto;
                padding: 20px;
            }
            .logo-img {
                height: 30%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                // animation-name: bounceInDown;
                // animation-duration: 1s;
                //background-color: $primary;
                margin-bottom: 20px;
                img {
                    width: 50%;
                    height: auto;
                    z-index: 1;
                }
            }
            .opcion{
                width: 100%;
                display: flex;
                align-items: center;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
            .large {
                color: $primary;
            }
            form {
                width: 90%;
            }
        }
    }

    .show {
        .splash {
            display: flex;
        }
        .login-form {
            display: flex !important;
        }
    }
}

@media only screen and (min-width: $device-sm) {
    .login {
        .login-content {
            .splash {
                .footer {
                    flex-direction: initial;
                    .footer-text{
                        text-align: left;
                        margin-right: 10px;
                    }
                    .footer-img{
                        margin-top: 0px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $device-md) {
    .login {
        .login-content {
            display: flex;
            .splash{
                display: flex;
                width: 50%;
            }
            .login-form {
                background-color: $bg-login ;
                display: flex;
                width: 50%;
                form {
                    width: 60%;
                }
            }
            
        }
    }
}