.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    z-index: 2;
}

.modal-cobertura {
    .modal-content {
        .content{
            img{
                margin: 10px 0px;
            }
            .img-modal{
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 50%;
            }
            .precio{
                color: black;
                font-size: 1rem;
                font-weight: bold;
            }
            .nombre {
                word-break: break-all;
                color: black;
                font-size: 0.9rem;
            }
            .pedido-list {
                max-height: 400px;
                padding: 10px !important;
                width: 100%;
                overflow-y: scroll;

                .img-regalo{
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    width: 22%;
                }
            
                .product-list {   
                    .item {
                        border-bottom: 0.5px solid $ligth;
                        padding: 10px 0px;
                        position: relative;
                        .nombre {
                            word-break: break-all;
                            color: black;
                            font-size: 0.9rem;
                        }
                        
                        .codigo {
                            font-size: 0.8rem;
                        }
                        .regular {
                            font-size: 0.9rem;
                            color: rgb(94, 87, 87);
                        }
                        .action {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            .agregar {
                                background-color: $primary;
                                padding: 12px 33px;
                                color: white;
                                border-radius: 25px;
                                font-size: 0.8rem;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
    }
}

.alert{
    .success {
        .btn-large {
            background-color: #4caf50;   
        }
        .outline {
            background-color: white;
            color: #4caf50;
            border-color: #4caf50;
        }
        .MuiAlert-icon {
            color: #4caf50;
        }
    }
    .info {
        .btn-large {
            background-color: #2196f3;   
        }
        .outline {
            background-color: white;
            color: #2196f3;
            border-color: #2196f3;
        }
        .MuiAlert-icon {
            color: #2196f3;
        }
    }
    .warning {
        .btn-large {
            background-color: #ff9800;   
        }
        .outline {
            background-color: white;
            color: #ff9800;
            border-color: #ff9800;
        }
        .MuiAlert-icon {
            color: #ff9800;
        }
    }
    .error{
        .btn-large {
            background-color: $primary;   
        }
        .outline {
            background-color: white;
            color: $primary;
            border-color: $primary;
        }
        .MuiAlert-icon {
            color: $primary;
        }
    }
    .modal-content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        border-radius: 10px;
        .content{
            padding: 15px 15px;
            height: initial;
            min-height: initial;
            max-height: initial;
            
            img{
                margin: 10px 0px;
            }
            .alert-content{
                margin: 10px 0px;
            }
            .alert-buttons{
                margin-top: 40px;
                button {
                    margin-top: 10px;
                    font-weight: bold;
                }
            }
        }
    }
}

.modal-content {
    width: 100%;
    background-color: white;
    .content {
        padding-top: 10px;
        width: 100%;
        padding: 40px 15px;
        overflow: auto;
        height: 100vh;
        
        img{
            width: 50%;
            margin: 40px 80px;
        }

        .margin{
            margin: 18px 0px
        }
        .modal-logo {
            width: auto;
            height: 45px;
            display: flex;
            margin: auto;
        }

        .alert-icon{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .alert-content {
            text-align: center;
            .alert-title {
                font-size: 1.5rem;
                font-weight: bold;
            }
            .alert-message {
                font-size: 1.0rem;
            }
            .content-img{
                width: 100%;
                padding: 0% 37%;
                display: flexbox;
                align-content: center;
            }
        }
    }

    .title{
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        .detail{
            padding:15px ;
            border-radius: 10px;
            background-color: #2098de;
            color: white;
        }
        p{
            color: $primary;
            padding-top: 15px;
            font-size: 1.5rem;
            text-align: center;
            font-weight: bold;
            width: 80%;
        }

        .close-button {
            position: absolute;
            right: 15px;
            text-align: center;
            cursor: pointer;
            border-radius: 0.25rem;
        }
        .close-button:hover {
            opacity: 0.6;
        }
   
    }
}

.publicidad{
    .modal-content {
        position: absolute;
        top: 20%;
        left: 10%;
        right: 10%;
        width: 80%;
        height: 44%;
        border-radius: 10px;
        background-color: transparent;
        .content{
            min-height: 400px;
            max-height: 600px;
            height: initial;  
            border-radius: 10px;
            padding: 0px;
            .img{
                border-radius: 10px;
            }
        }
        span{
            position: absolute;
            right: -10px;
            background-color: white;
            border-radius: 50%;
            padding: 5px;
            border: 2px solid $primary;
            display: flex;
            top: -10px;
        }
        
    }
}

.error{
    color: #716a6a;
    font-size: 0.9rem;
    font-weight: bold;
}

.show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.list-sucursales {
    width: 100%;
    .item {
        padding: 10px 0px;
        border-bottom: 1px solid $primary;
        cursor: pointer;
        p {
            font-size: 0.8rem;
        }
    }
    .item:hover {
        background-color: $gray;
    }
}


@media only screen and (min-width: $device-md) {
    .modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        border-radius: 10px;
        .content {
            min-height: 400px;
            max-height: 600px;
            height: initial;
        }
    }

    .publicidad{
        .modal-content {
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 500px;
            border-radius: 10px;
            .content {
                min-height: 500px;
                max-height: 600px;
                height: initial;
                padding: 0px;
                .img{
                    border-radius: 10px;
                }

                
            }
        }
    }

    .modal-cobertura {
        .modal-content {
            width: 500px !important;
        }
    }

    .alert {
        .modal-content {
            width: 400px !important;
        }
    }

    .table-content{
        width: 80%;
    }

    .edit-content{
        width: 50%;
    }

    .create-content{
        width: 60%;
    }
    
}

