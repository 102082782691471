.content-layout{
    padding: 140px 5px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
}

.creacion{
    padding: 20px 5px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    //max-width: 1300px;

    
    .crearjr-content{
        padding-left:30px;
        padding-right:30px;
        display: flex;
        flex-direction: column-reverse;
        .secciones {
            width: 100%;
            
        }
        .registro{
            width: 100%;
            padding: 10px;
            
        }
    }
}

.datosjr-titulo{
    margin-top: 20px;
    margin-bottom: 20px;
    h2{
        display: initial;
        border-bottom: 2px solid $primary;
        font-weight: 900;
        color: #616161;
    }
    a{
        margin-left: 20px;
        // background-color: #f64f9d;
        // color: white;
        font-size: 0.9rem;
        padding: 5px 20px;
        border-radius: 15px;
        font-weight: bold;
        img{
            margin-left: 10px;
        }
    }
    a:hover{
        opacity: 0.8;
    }
}

.seccion{
    padding: 0px 10px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
    
    .crearjr-content{
        display: flex;
        flex-direction: column-reverse;
        .secciones {
            width: 100%;
            
        }
        .registro{
            width: 100%;
            padding: 10px;
        }
    }
}


@media only screen and (min-width: $device-sm) {
    .creacion{
        padding: 20px 0px;
    }
    
}

@media only screen and (min-width: $device-md) {
    .creacion{
        padding: 20px 0px;
    }
    .seccion{
        padding: 10px 20px;
    }

    .crearjr-content{
        padding-left:10px;
        padding-right:10px;
        flex-direction: row !important;
        display: flex;
        .secciones {
            width: 55% !important;
            
        }
        .registro{
            width: 45% !important;
            padding: 10px;
        }
    }
}

@media only screen and (min-width: $device-lg) {
    .content-layout{
        padding: 40px 5px;
    }
    .crearjr-content{
        flex-direction: row !important;
        padding-left:10px;
        padding-right:10px;
        .secciones {
            width: 60% !important;
            
        }
        .registro{
            width: 40% !important;
            padding: 10px;
        }
    }
}
