.breadcrumb {
    display: none;
    width: 100%;
    // border-bottom: 1px solid #eee;
    .breadcrumb {
        display: flex;
        align-items: center;
        padding: 20px 0px;
        .breadcrumb-item {
            padding: 0px 5px;
            font-size: 0.8rem;
            color: $ligth;
            a{
                color: $ligth;  
            }
            a:hover {
                color: $primary;
            }
        }
        .active {
            color: $primary;
            font-weight: bold;
        }  
        .breadcrumb-item+.breadcrumb-item::before {
            display: inline-block;
            padding-right: .5rem;
            color: $ligth;
            content: "/";
        }
    }
}

@media only screen and (min-width: $device-md) {
    .breadcrumb {
        display: flex;
    }
}

