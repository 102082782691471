.pedido-list {
    // margin-left: auto;
    // margin-right: auto;
    // max-width: 1200px;
    padding: 180px 10px;
    width: 100%;

    .message{
        display: flex;
        font-weight: bold;
        color:$ligth;
        font-weight: bold;
        font-size: 1.7rem;
    }

    .img-regalo{
        position: absolute;
        top: 10px;
        left: 10px;
        width: 20%;
        min-width: 60px;
        max-width: 100px;
    } 

    .product-list {
        
        .item {
            border-bottom: 0.5px solid $ligth;
            padding: 10px 0px;
            position: relative;  
            .nombre {
                word-break: break-all;
                color: black;
                font-size: 0.9rem;
            }
            .codigo {
                font-size: 0.8rem;
            }
            .precio {
                color: rgb(94, 87, 87);
                font-size: 1rem;
                font-weight: bold;
            }
            .precioD {
                color: rgb(223, 99, 99);
                font-size: 1rem;
                text-decoration: line-through;
            }
            .regular {
                font-size: 0.9rem;
                color: rgb(94, 87, 87);
            }
            .action {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .agregar {
                    background-color: $primary;
                    padding: 12px 33px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                }
                .controls {
                    border-radius: 25px;
                    border: 1px solid $primary;
                    padding: 10px 10px;
                    display: flex;
                    align-items: center;
                    input {
                        width: 60px;
                        padding: 0px 5px;
                        text-align: center;
                        font-size: 1rem;
                    }
                    button {
                        background-color: transparent;
                        display: flex;
                        opacity: 1;
                    }
                }
            }
        }

        .item-carrito {
            min-height: 80px;
            .img-product {
                object-fit:contain;
                max-height: 203px;
                padding: 0px 20px;
                width: 30%;
                height: auto;
            }
            display: flex;
            align-items: center;
            .start {
                width: 90%;
            }
            .end {
                width: 35%;
            }
        }
    }

    .product-card{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1rem;
        margin-top: 20px;  
        .card {
            border-radius: 20px;
            box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
            padding: 10px;
            .card-img {
                min-height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
               
            }
            .card-img:hover {
                .img-product {
                    transform: scale(1.0);
                }
            }
            .img-product {
                object-fit:contain;
                width: 100%;
                height: 100%;
                max-height: 203px;
                transform: scale(0.9);
                z-index: -1;
            }
            .nombre {
                font-family: "Nunito", serif;
                font-size: 0.9rem;
                min-height: 40px;
                text-align: center;
                word-break: break-word;
            }
            .codigo {
                font-size: 0.8rem;
                text-align: center;
            }
            .precioDesc{
                text-align:center;
            }
            .precioDesc p{
                font-size: 1.2rem;
                display: inline;
                font-weight: bold;
            }
            .precioTachado {
                color: rgb(167, 167, 167);
                text-decoration:line-through;
                margin-right: 10px;
            }
            .action {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
                .agregar {
                    background-color: $primary;
                    padding: 12px 33px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                }
                .controls {
                    border-radius: 25px;
                    border: 1px solid $primary;
                    padding: 10px 10px;
                    display: flex;
                    border: 1px solid $primary;
                    padding: 10px 10px;
                    display: flex;
                    input {
                        width: 60px;
                        padding: 0px 5px;
                        text-align: center;
                        font-size: 1rem;
                    }
                    
                    button {
                        background-color: transparent;
                        display: flex;
                        opacity: 1;
                    }
                }
            }
            .agotado {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
                p {
                    color: $ligth;
                    padding: 10px 10px;
                    display: flex;
                    font-weight: bold;
                    font-size: 1.2rem;
                }
            }
        }
    }
}

.titulo-lista {
    text-align: center;
    color: $ligth;
}

.titulo-carrio {
    text-align: center ;
    color: $ligth ;
    font-weight: 700;
}

.content-wompi{
    button{
        cursor: pointer;
        width: 100%;
        color: white;
        height: 40px;
        font-size: 0.9rem;
        border-radius: 5px;
        box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
    }
}

.sugerido {
    padding: 80px 10px !important;
    .checkbox {
        margin-bottom: 20px;
    }
}

.carrito {
    padding: 100px 10px !important;  
}

.carrito-validacion {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid red;
    background-color: #fff;
    display: flex;
    border-radius: 5px;
    .contenido{
        margin-left: 10px;
    }
}

.error{
    color: red;
    font-size: 0.9rem;
    font-weight: bold;
}
.total {
	// position: absolute;
	// bottom: 2px;
	padding: 10px 10px;
    width: 100%;
    background-color: white;
	.total-content{
		background-color: $gray;
		border-style: dotted;
		border-color: $ligth;
		border-radius: 5px;
		border-width: 1px;
		border-spacing: 1px;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
		.item {
			display: flex;
			.opc {
                font-weight: bold;
                width: 50%;
                font-size: 1.1em;
            }
            .val {
                width: 50%;
                text-align: right;
                color: rgb(94, 87, 87);
                font-weight: bold;
                font-size: 1.1em;
            }
		}
    }
    .check-content{
        p{
            color: $ligth;
        }
		border-spacing: 1px;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
        ::-webkit-input-placeholder { 
            color: #5e5757; 
            font-weight: 700; 
            font-family: "Nunito", serif;
            font-size: 16px;
        } 

        .input{
            background-color: transparent;
            border: none;
            border-bottom: 1px solid $ligth;
            color: $ligth;
            box-sizing: border-box;
            font-family: "Nunito", serif;
            font-size: 18px;
            height: 50px;
            margin: -8px 0 0 0px;
            padding: 10px 0px;
            position: relative;
            top: 50%;
            width: 100%;
        }
        .inputObservacion{
            border: 0.5px solid #ababab;
            border-radius: 4px;
            box-sizing: border-box;
            font-family: "Nunito", serif;
            font-size: 18px;
            margin: -8px 0 0 0px;
            padding: 10px 3px;
            position: relative;
            top: 50%;
            width: 100%;
            height: 100px;
        }
		.item {
			display: flex;
			.opc {
                color: rgb(94, 87, 87);
                font-weight: bold;
                width: 50%;
                font-size: 1.1em;
            }
            .val {
                width: 50%;
                text-align: right;
                color: $ligth;
                font-weight: bold;
            }
		}
    }

    .opcc {
        color: rgb(94, 87, 87) !important;
        font-weight: bold;
        width: 50%;
    }

    .valc {
        width: 100%;
        text-align: right;
        color: $ligth;
        font-weight: bold;
    }
    
    button{
        margin-top: 10px;
    }
    .outline{
        background-color: white;
        border: 1px solid $primary;
        color: $primary;
    }
}

.load-mas {
    text-align: center;
    margin-top: 10px;
    button {
        background-color: $gray;
        font-size: 0.9rem;
        font-weight: bold;
        padding: 10px;
        border-radius: 5px;
        color: $primary;
    }
}

.cargando {
    width: 100%;
    .cargandoItem{
        width: 100%;
        .itemsRect1{
            width: 75%;
        }
        .itemsRect2{
            width: 55%;
        }
    }

    .cargandoinfoUsuario{
        width: 100%;
        .infoUsuario{
            width: 90%;
        }
        .infoUsuario2{
            width: 50%;
        }
    }

    .cargandoPrecios{
        height: 100px;
        width: 100%;
        .itemsPrecio1{
            width: 40%;
        }
        .itemsPrecio2{
            width: 35%;
        }
        .itemsPrecio3{
            width: 20%;
        }
        .itemsPrecio4{
            width: 30%;
        }
    }
}

@media only screen and (min-width: $device-sm) {
    .product-card {
        grid-template-columns: repeat(3, 1fr) !important;
    }

    .item-carrito {
        .img-product {
            width: auto !important;
            height: 100px !important;
        }
    }

    .cargando {
        .cargandoItem{
            .itemsRect1{
                width: 35%;
            }
            .itemsRect2{
                width: 25%;
            }
        }
        
        .cargandoPrecios{
            .itemsPrecio1{
                width: 30%;
            }
            .itemsPrecio2{
                width: 25%;
            }
            .itemsPrecio3{
                width: 10%;
            }
            .itemsPrecio4{
                width: 20%;
            }
        }
    }
    
}

@media only screen and (min-width: $device-md) {
    .product-card {
        grid-template-columns: repeat(4, 1fr) !important;
    }
    .content-carrito{
        display: flex;
    }
    .carrito{
        padding: 100px 10px;
        display: inline-block;
        width: 70%;
    }
    .total{
        display: inline-block;
        width: 30%;
        position: initial;
        padding: 120px 10px;
    }
    .cargando {
        .cargandoItem{
            .itemsRect1{
                width: 35%;
            }
            .itemsRect2{
                width: 25%;
            }
        }
        
        .cargandoPrecios{
            .itemsPrecio1{
                width: 30%;
            }
            .itemsPrecio2{
                width: 25%;
            }
            .itemsPrecio3{
                width: 20%;
            }
            .itemsPrecio4{
                width: 30%;
            }
        }
    }
}

@media only screen and (min-width: $device-lg) {
    
    .product-card {
        grid-template-columns: repeat(5, 1fr) !important;
    }
    .cargando {
        .cargandoItem{
            .itemsRect1{
                width: 25%;
            }
            .itemsRect2{
                width: 15%;
            }
        }
        
        .cargandoPrecios{
            .itemsPrecio1{
                width: 40%;
            }
            .itemsPrecio2{
                width: 35%;
            }
            .itemsPrecio3{
                width: 20%;
            }
            .itemsPrecio4{
                width: 30%;
            }
        }
    }
}