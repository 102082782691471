.datos{
    padding: 120px 10px;
    width: 100%;
    .card {
        a{
            cursor:"pointer";
            float: right;
        }
        box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
        padding: 20px;
        border-radius: 5px;
        h3{
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
        }
        .info{
            margin-top: 20px;
            font-size: 0.9rem;
        }
       
    }
}

.historial {
    a{
        // .seguir-comprando{
        // }
        padding: 6px 50px;
        border-radius: 5px;
        background-color: $primary;
        height: 40px;
        color:white;
        cursor: pointer;
        float: right;
        box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    padding: 100px 10px;
    width: 100%;
}

@media only screen and (min-width: $device-md) {
    .datos{
        padding: 120px 10px;
    }
    .historial {
        padding: 120px 60px;
    }
}