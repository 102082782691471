.manual {
    background-color: $primary;
    width: 100%;
    height: 100%;
    padding: 40px;
    
    .manual-logo{
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            height: 80px;
            width: auto;
        }
    }
    .manual-card{
        box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
        border-radius: 15px;
        background-color: white;
        position: relative;
        z-index: 1;
       
        .manual-menu {
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            ul{
                display: flex;
                padding: 25px 12px;
                li {
                    a {
                        padding: 10px 10px;
                        font-size: 1.2rem;
                        font-weight: bold;
                        color: $primary;
                        border-radius: 15px;
                        padding: 10px;
                    }
                    .active{
                        background-color: $primary;
                        color: white;
                    }
                }
            }
        }
        .manual-pasos{
            padding: 10px;
            z-index: 2;
            .pasos-titulo{
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: $ligth;
                font-size: 0.9rem;
                font-weight: bold;
                h2{
                    width: 60%;
                }
            }
            .pasos-img {
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        .manual-registro, .manual-session, 
        .manual-producto, .manual-pedido{
            z-index: 2;
            position: relative;
            .ma-re-fondo{
                position: absolute;
                top: 0px;
                width: 100%;
                .izq {
                    height: 300px;
                    width: 32%;
                }
            }
            .ma-re-content{
                padding: 20px;
                .ma-re-titulo {
                    text-align: center;
                    color: $primary;
                    font-weight: bold;
                    margin-bottom: 40px;
                }
                .ma-re-slide {
                    color: $ligth;
                    text-align: center;
                    font-size: 0.9rem;
                    p{
                        margin-top: 10px;
                    }
                }
            }
        }

        .manual-entrega {
            z-index: 2;
            .ma-re-titulo {
                text-align: center;
                color: $primary;
                font-weight: bold;
                margin-bottom: 40px;
            }
            .entrega-content{
                display: flex;
                width: 100%;
                align-items: center;
                padding: 40px;
                .entrega-texto{
                    width: 50%;
                    padding: 40px;
                    p{
                        margin-bottom: 20px;
                        font-size: 1.2rem;
                        color: $ligth;
                    }
                }
                .entrega-img{
                    width: 50%;
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .manual-descarga{
            z-index: 2;
            a{
                img{
                    width: 100%;
                    height: auto;
                }
            }
        }

        .manual-footer{
            z-index: 2;
            padding: 40px;
            .ma-fo-content {
                display: flex;
                align-items: center;
                justify-content: center;
                .ma-fo-img{
                    img{
                        height: 100px;
                        width: auto;
                    }
                }
                .ma-fo-texto{
                    font-size: 0.9rem;
                    color: $ligth;
                    max-width: 400px;
                    margin-left: 20px;
                }
            }
        }
    }
}

.manul-fondo-left{
    position: absolute;
    z-index: -1;
    left: 0px;
    display: grid;
    .fnd-1{
        position: relative;
        width: 50%;
        top: 670px;
        left: 0px;
    }
    .fnd-3{
        position: relative;
        width: 40%;
        top: 1300px;
        left: 0px;
    }
}
.manual-fondo-rigth {
    position: absolute;
    z-index: -1;
    right: 0px;
    .fnd-2 {
        position: relative;
        width: 50%;
        top: 1400px;
        left: 0px;
        float: right;
    }
}

.owl-carousel.owl-drag .owl-item{
    color: $ligth;
    text-align: center;
    font-size: 0.9rem;
    p{
        margin-top: 10px;
    }
}

@media screen and (max-width: 600px) {
    .manual {
        padding: 10px;
        .manual-card{
            .manual-menu{
                display: none;
            }
            .manual-entrega{
                .entrega-content{
                    flex-direction: column;
                    padding: 0px;
                    .entrega-texto{
                        width: 100%;
                        padding: 20px;
                        font-size: 1rem;
                    }
                    .entrega-img {
                        width: 100%;
                    }
                }
            }
        }
    }
}