.table-responsive {
  overflow: auto;
  .table-mensaje {
    padding: 10px 0px;
    font-size: 0.8rem;
  }
}

.table-title{
  p,h3{
    color: $ligth;
    font-weight: bold;
    color: $primary;
    font-size: 1.5em;
  }
  margin-bottom: 25px;
}

.mytabla{
  display: inline-table !important;
  width: 100% !important;
  font-size: 0.8rem !important;
  border: 1px solid $ligth;
  border-collapse: collapse;
  
  thead{
      .bold{
        font-weight: bold;
      }
      background-color: $ligth;
      text-transform: uppercase !important;
      font-size: 0.6rem !important;
      color: #FFFFFF;
  }
  tbody{
      border-collapse: none !important;
      color: #000000;
      i{
          color: $ligth;
      }
  }
  tfoot{
    font-size: 1.2rem;
    font-weight: bold;
  }
  tr{
      height: 40px !important;
      border-bottom: 1px solid $ligth;
  } 
  th, td{
      a{
        background-color: white;
        cursor: pointer;
      }
      padding-left: 10px !important;
      padding-right: 10px !important;
  }
}

.tabla-cart {
  thead {
      text-transform: uppercase;
      font-size: 0.6rem;
  }
}

table th.numero {
  text-align: right;
  padding-right: 5px;
}

table td.numero {
  text-align: right;
  padding-right: 5px;
}

table td.accion {
  text-align: right;
  padding-left: 10px;
}

@media screen and (max-width: 600px) {
  .mytabla-responsive {
      width:100%;
      thead {
          display: none;
      }
      tr:nth-of-type(2n) {
          background-color: inherit;
      }
      tr td:first-child {
          background: #f0f0f0;
          font-weight:bold;
          font-size:1.3em;
      }
      tbody td {
          display: block;
      }
      tbody td:before {
          content: attr(data-th);
          display: block;
          text-align:center;
      }
  }
  
}

