.slider .slide {
    background-color: white !important;
}

.carousel.carousel-slider .control-arrow:hover {
    background: white;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid $primary;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid $primary;
}

.carousel .control-dots .dot{
    margin-top: 100px;
    background: $primary;
}


.detalle{
    padding: 80px 10px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    .detail-product {
        .detail-content {
            display: flex;
            flex-direction: column;
            border: 1px solid #ebebeb;
            border-radius: 20px;
            margin-bottom: 20px;
            .detail-photo{
                width: 100%;
                padding: 20px 10%;
                display: flex;
                align-items: center;
                min-height: 300px;
                img {
                    max-height: 480px;
                    object-fit: contain;
                    width: 99%;
                    height: auto;
                }
            }
            .detail-info {
                width: 100%;
                padding: 20px;
                iframe{
                    width: 100%;
                    padding: 10px 0px;
                }
                .detail-shop {
                    color: $ligth;
                    font-size: 0.9rem;
                }
                .detail-title {
                    font-weight: bold;
                    font-size: 1.5rem;
                    margin-top: 10px;
                }
                .detail-price {
                    font-weight: bold;
                    font-size: 1.5rem;
                    margin-top: 20px;
                }
                .detalle-precioTachado {
                    color: rgb(167, 167, 167);
                    text-decoration:line-through;
                    margin-right: 10px;
                    font-size: 1.0rem;
                }
                .detail-additional {
                    border: 1px solid white;
                    padding: 10px;
                    margin-top: 20px;
                    background-color: white;
                    border-radius: 5px;
                    .envio{
                        color: $primary;
                    }
                    p {
                        display: flex;
                        align-items: center;
                        font-size: 0.9rem;
                        font-weight: bold;
                        margin-bottom: 3px;
                        img {
                            margin-right: 10px;
                        } 
                    }
                    
                }
                .detailcontrol{
                    margin-top: 20px;
                    label {
                        font-weight: bold;
                        font-size: 0.9rem;
                    }
                    textarea {
                        width: 100%;
                        height: 80px;
                        border: 1px solid #ebebeb;
                        padding: 10px;
                        font-size: 0.9rem;
                        margin-top: 10px;
                        resize: none;
                    }
                }
                .action {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    .agregar {
                        background-color: $primary;
                        padding: 12px 33px;
                        color: white;
                        border-radius: 25px;
                        font-size: 0.8rem;
                        font-weight: bold;
                    }
                    .controls {
                        border-radius: 25px;
                        border: 1px solid $primary;
                        padding: 10px 10px;
                        display: flex;
                        border: 1px solid $primary;
                        padding: 10px 10px;
                        display: flex;
                        input {
                            width: 60px;
                            padding: 0px 5px;
                            text-align: center;
                            font-size: 1rem;
                        }
                        
                        button {
                            background-color: transparent;
                            display: flex;
                            opacity: 1;
                        }
                    }
                }
                .agotado {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    p {
                        color: $ligth;
                        padding: 10px 10px;
                        display: flex;
                        font-weight: bold;
                        font-size: 1.2rem;
                    }
                }
                
            }
        }
    }
    .pedido-list{
        padding: 20px 0px !important;
    }
}

@media only screen and (min-width: $device-md) {
    .detalle{
        .detail-product {
            .detail-content {
                flex-direction: row;
                .detail-photo{
                    width: 60%;
                }
                .detail-info {
                    width: 40%;
                }
            }
        }
    }
   
}


.centrado {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-27%, -199%);
    color: rgb(255, 252, 252);
    font-size: 0.8rem;
    z-index: 1;
}

.card-info-vi {
    position: absolute;
    color: #c5106f;
    right: 7rem;
    top: 5px;
    padding: 0px 0px;
    font-size: 0.9rem;
    font-weight: bold;


}