form {
    .form-control {
        display: grid;
        margin-top: 10px;
        position: relative;
        .minus{
            font-size: 0.75rem;
        }
        label {
            color: $ligth;
            margin-bottom: 5px;
            font-weight: bold;
            font-size: 0.9rem;
            span{
                color: red;
            }
        }
        select {
            height: 40px;
            background-color: white;
            border: 1px solid white;
            font-size: 0.9rem;
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 5px;
            color: $ligth;
            background-color: #f5f5f5;
            width: 100%;
        }
        input {
            height: 40px;
            background-color: white;
            border: 1px solid $primary;
            font-size: 0.9rem;
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 20px;
            color: $ligth;
            width: 100%;
        }
        select:active, select:focus{
            background-color: white;
            border: 1px solid $primary;
        }
        input[type='text'], input[type='password'], input[type="number"],
        input[type="date"], input[type="email"] {
          height: 40px;
        }
        input[type='text']:focus, input[type='text']:active,
        input[type='password']:focus, input[type='password']:active,
        input[type='number']:focus, input[type='number']:active,
        input[type='date']:focus, input[type='date']:active,
        input[type='email']:focus, input[type='email']:active
        {
          background-color: white;
          border: 1px solid $primary;
          //box-shadow: 0px 2px 8px 0px $primary;
        }
        .input-otp {
            display: flex;
            align-items: center;
            justify-content: center;
            input {
                margin: 10px;
                text-align: center;
                font-weight: bold;
            }
        }
        .input-error{
            border: 1px solid red !important;
        }
        .error {
            color: red;
            font-size: 0.7rem;
            margin-top: 10px;
        }
        .suceess {
            color: $dark;
            font-size: 0.9rem;
            margin-top: 10px;
        }

        .autocomplete-dropdown{
            position: absolute;
            box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
        }

        .input-direcion {
            select {
                width: 100%;
            }
            .direccion-group{
                width: 100%;
                display: flex;
                align-items: center;
                margin-top: 10px;
                input {
                    width: 30%;
                }
                label {
                    width: 5%;
                    text-align: center;
                }
            }
        }
        .map {
            margin-top:  10px;
        }

        // .form-direccion {
        //     position: absolute;
        //     top: 65px;
        //     background-color: white;
        //     z-index: 1;
        //     padding: 10px;
        //     width: 100%;
        //     box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
        //     font-size: 0.9rem;
        //     display: flex;
        //     align-items: center;
        //     img{
        //         margin-right: 10px;
        //     }
        // }

        
    } 

    .button-hidden {
        float: right;
        position: relative;
        z-index: 2;
        margin-right: 16px;
        margin-top: -28px;
    }
    
    .error {
        color: red;
        font-size: 0.8rem;
    }

    .form-row {
        display: flex;
        .first {
            width: 30%;
        }
        .end {
            margin-left: 10px;
            width: 70%;
        }
        .form-control{
            width: 50%;
        }
        .form-control:first-child{
            margin-right: 10px;
        }
    }
   

    .form-check {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
        margin-top: 10px;
        input {
            margin-right: 10px;
        }
        a {
            font-size: 0.8rem;
            color:$dark;
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .form-link {
        padding: 20px 0px;
        text-align: center;
        a {
            font-size: 0.9rem;
            color:$dark;
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .title-map{
        margin-top: 20px;
        color: $primary;
        text-align: center;
        font-size: 0.8rem;
    }

    .btn-cobertura{
        width: 100%;
        background-color: $primary;
        color: white;
        height: 40px;
        font-size: 0.9rem;
        border-radius: 5px;
        box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
    }

    button {
        margin-top: 20px;
    }
}

// .pac-container{
//     margin-top: 40px;
//     border-top: none !important;
//     box-shadow: 0 2px 8px 0 rgba(0,0,0,.15) !important;
//     width: 100%;
// }

.btn-large {
    cursor: pointer;
    background-color: $primary;
    width: 100%;
    color: white;
    height: 40px;
    font-size: 0.9rem;
    border-radius: 20px;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
}

.btn-catalogo{
    width: 25%;
}

.outline{
    background-color:white;
    border: 1px solid $primary;
    color: $primary;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
}

.input-notificacion {
    select {
        width: 100%;
    }
    .direccion-group{
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 10px;
        input {
            width: 30%;
        }
        label {
            width: 5%;
            text-align: center;
        }
    }
}