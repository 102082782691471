.pack{
    padding: 80px 10px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    .pack-product {
        .pack-content {
            display: flex;
            flex-direction: column;
            border: 1px solid #ebebeb;
            border-radius: 20px;
            margin-bottom: 20px;
            padding: 10px;
            .pack-productos{
                width: 100%;
                padding: 20px 10%;
                display: flex;
                align-items: center;
                min-height: 400px;
                img {
                    height: auto;
                }
            }
            .pack-info {
                width: 100%;
                padding: 20px;
                .pack-shop {
                    color: $ligth;
                    font-size: 0.9rem;
                }
                .pack-title {
                    font-weight: bold;
                    font-size: 2.5rem;
                    margin-top: 10px;
                }
                .pack-price {
                    font-weight: bold;
                    font-size: 2.5rem;
                    margin-top: 20px;
                }
                .pack-additional {
                    border: 1px solid #ebebeb;
                    padding: 10px;
                    margin-top: 20px;
                    background-color: #ebebeb;
                    border-radius: 5px;
                    .envio{
                        color: $primary;
                    }
                    p {
                        display: flex;
                        align-items: center;
                        font-size: 0.9rem;
                        font-weight: bold;
                        margin-bottom: 3px;
                        img {
                            margin-right: 10px;
                        } 
                    }
                    
                }
                .detailcontrol{
                    margin-top: 20px;
                    label {
                        font-weight: bold;
                        font-size: 0.9rem;
                    }
                    textarea {
                        width: 100%;
                        height: 80px;
                        border: 1px solid #ebebeb;
                        padding: 10px;
                        font-size: 0.9rem;
                        margin-top: 10px;
                        resize: none;
                    }
                }
                .action {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    .agregar {
                        background-color: $primary;
                        padding: 12px 33px;
                        color: white;
                        border-radius: 25px;
                        font-size: 0.8rem;
                        font-weight: bold;
                    }
                    .controls {
                        border-radius: 25px;
                        border: 1px solid $primary;
                        padding: 10px 10px;
                        display: flex;
                        border: 1px solid $primary;
                        padding: 10px 10px;
                        display: flex;
                        input {
                            width: 60px;
                            padding: 0px 5px;
                            text-align: center;
                            font-size: 1rem;
                        }
                        
                        button {
                            background-color: transparent;
                            display: flex;
                            opacity: 1;
                        }
                    }
                }
                .agotado {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    p {
                        color: $ligth;
                        padding: 10px 10px;
                        display: flex;
                        font-weight: bold;
                        font-size: 1.2rem;
                    }
                }
                
            }
        }
    }
    .pedido-list{
        padding: 20px 0px !important;
    }
}

@media only screen and (min-width: $device-md) {
    .pack{
        .pack-product {
            .pack-content {
                flex-direction: row;
                .pack-productos{
                    width: 60%;
                }
                .pack-info {
                    width: 40%;
                }
            }
        }
    }
   
}