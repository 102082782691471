.marca {
    width: 100%;
    display: flex;
    flex-direction: column;
    .marca-content{
        width: 100%;
        margin-top: 10px;
        overflow-x: overlay;
        .item{
            display: flex;
            padding: 5px 0px;
            a{
                display: flex;
                align-items: center;
                div{
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 60px;
                    height: 60px;
                    padding: 10px;
                    .img-marca{
                        width: 40px;
                    } 
                }
                p{
                    margin-left: 10px;
                    color: #616161;
                    font-weight: bold;
                    font-size: 0.7rem;
                    text-transform: uppercase;
                }
            }
        }
    }
    .marca-content::-webkit-scrollbar * {
        background:transparent;
    }
    .marca-content::-webkit-scrollbar-thumb {
        background:rgba(255,0,0,0.1) !important;
    }
    
}

@media only screen and (min-width: $device-md) {
    .marca {
        .marca-content{
            .item{
                a{
                    div{ 
                        width: 50px;
                        height: 50px;
                        .img-marca{
                            width: 30px;
                        }
                    }
                }
            }
        }
    }
}
