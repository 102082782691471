.cartera {
  a {
    margin: 5px;
    padding: 6px 50px;
    border-radius: 5px;
    background-color: $primary;
    height: 40px;
    color: white;
    cursor: pointer;
    float: right;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-left{
    input{
      background-color: #ffffff;
      border: 1px solid #787878;
      border-radius: 5px;
      padding: 2px;
    }
  }

  .content-wompi {
    button {
      cursor: pointer;
      width: 100%;
      color: white;
      height: 40px;
      font-size: 0.9rem;
      border-radius: 5px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    }
  }

  .total {
    margin: 5px;
    padding: 30px 0px;
    height: 40px;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;

    .total-content {
      background-color: $gray;
      border-style: dotted;
      border-color: $ligth;
      border-radius: 5px;
      border-width: 1px;
      border-spacing: 1px;
      padding: 10px;
      margin-left: auto;
      margin-right: auto;
      max-width: 1200px;
      width: 100%;
      .item {
        display: flex;
        .opc {
          font-weight: bold;
          width: 50%;
          font-size: 1.1em;
        }
        .val {
          width: 50%;
          text-align: right;
          color: rgb(94, 87, 87);
          font-weight: bold;
          font-size: 1.1em;
        }
      }
    }
  }

  .carteraPdf {
    display: none;
  }

  .boton {
    margin: 5px;
    padding: 6px 37px;
    border-radius: 5px;
    background-color: $primary;
    height: 40px;
    color: white;
    cursor: pointer;
    float: right;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  padding: 120px 10px;
  width: 100%;

  table {
    width: 100%;
    td {
      border: 1px solid #ddd;
      padding: 8px;
    }

    tfoot {
      border: none;
      background: gray;
      td {
        border: none;
        padding: 8px;
      }
    }
  }
}

@media only screen and (min-width: $device-md) {
  .cartera {
    padding: 120px 60px;
  }
}



//$device-mv se pone un numero expecifico para el diseño de estado cuenta 
@media only screen and (max-width: 380px) {
  .cartera {
    a{
      width: 100%;
    }

    .boton {
      width: 100%;
    }
  }
}