.catalog-content {
    display: flex;
    flex-direction: row;
    .catalog-filter{
       display: none;
    }
    .catalog-list{
        width: 100%;
    }
}

.filter-xs {
    display: flex;
    width: 20%;
    button {
        position: fixed;
        right: 20px;
        top: 220px;
        background-color: $primary;
        color: white;
        padding: 2px 10px;
        border-radius: 2px;
        z-index: 1;
    }
   
}

@media only screen and (min-width: $device-md) {
    .catalog-content {
        .catalog-filter{
            display: flex;
            width: 20%;
            margin-right: 30px;
            padding: 20px;
            .contenedor_filter{
                margin-top: 120px;
            }
        }
        .catalog-list{
            width: 80%;
        }
    }

    .filter-xs {
        display: none;
    }
}