.categoria {
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    width: 100%;
    box-shadow: 0 6px 8px 0 rgba(0,0,0,.15);
    display: flex;
    align-items: center;
    .arrow {
        width: 5%;
        background-color: white;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ul {
        display: flex;
        max-height: 80px;
        padding: 12px 10px;
        margin: 0 auto;
        white-space: nowrap;
        width: 90%;
        overflow-x: scroll;
        -ms-overflow-style: none; 
        scrollbar-width: none;
        li {
            text-align: center;
            flex-grow: 1;
            a{
                padding: 10px 12px;
                font-size: 0.8rem;
                text-transform: uppercase;
                text-align: center;
                cursor: pointer;
            } 
            .active {
                border-bottom: 2px solid $primary;
                color: $primary;
                font-weight: bold;
            }
        }
    }  
    ul::-webkit-scrollbar {
        display: none;
        overflow: -moz-scrollbars-none;
    }
     
}