$scroll: #cfd8dc;

::-webkit-scrollbar {
    width: 5px;
    height: 7px;
    margin-top: 10px;
}
::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
::-webkit-scrollbar-thumb {
    background: $scroll;
    border: 0px none #ffffff;
    border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
    background: $scroll;
}
::-webkit-scrollbar-thumb:active {
    background: $scroll;
}
::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
    background: transparent;
}
::-webkit-scrollbar-track:active {
    background: transparent;
}
::-webkit-scrollbar-corner {
    background: transparent;
}