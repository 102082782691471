.autocomplete {
    border: 1px solid #999;
    padding: 0.5rem;
    width: 300px;
}

.no-suggestions {
    color: #999;
    padding: 0.5rem;
    position: absolute;
    background-color: white;
    top: 65px;
    width: 100%;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
}

.suggestions {
    // border: 1px solid #999;
    // border-top-width: 0;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
    list-style: none;
    margin-top: 0;
    // max-height: 150px;
    // overflow-y: auto;
    padding-left: 0;
    width: calc(300px + 1rem);
    position: absolute;
    background-color: white;
    top: 65px;
    width: 100%;
    z-index: 1;
}

.suggestions li {
    padding: 0.5rem;
    font-size: 0.8rem;
}

.suggestion-active,
.suggestions li:hover {
    background-color: $gray;
    cursor: pointer;
    font-weight: 700;
}
