.navbar {
    background-color: $primary;
    position: fixed;
    z-index: 2;
    width: 100%;
    background-size: 90%;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);

    .nav-header {
        // background-color: $primary;
        padding: 8px 0px;
        .nav-content { 
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;
            max-width: 1200px;
            text-align: center;
            .subnav {
                display: flex;
                align-items: center;
                .icomenu {
                    margin: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                a{
                    cursor: pointer;
                    color: white;
                    padding-left: 15px;                    
                }
                a:hover{
                    font-weight: 700;
                    font-size: 1.2rem;
                }
                .active{
                    font-weight: 900;
                    text-decoration: underline;
                }
            }
            .nav-logo {
                width: 30%;
                img {
                    // width: auto;
                    // height: 60px;
                    width: 60px;
                }
                a{
                    cursor: pointer;
                }
            }
        }
    }


    .input-search{
        // background-color: $primary;
        margin-left: auto;
        margin-right: auto;
        padding: 10px 10px;
        max-width: 1200px;
        text-align: center;
        input {
            width: 100%;
            background-color: $gray;
            height: 40px;
            padding: 5px 10px;
            border-radius: 20px;
            font-size: 1rem;
            border: 1px solid $primary;
        }

        .close-search {
            float: right;
            position: relative;
            z-index: 2;
            margin-right: 9%;
            margin-top: -28px;
        }
    }

    .navmenu {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -5px;
        right: -8px;
            
        ul {
            display: flex;
            align-items: center;
            .location {
                a {
                    color: white;
                    display: flex;
                    flex-direction: column;
                    font-size: 0.6rem;
                    justify-content: center;
                    align-items: center;
                    font-weight: bold;
                }
                p{
                    white-space: nowrap;
                    max-width: 300px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                span{
                    display: flex;
                }
                img{
                    display: none;
                }
            }
            .profile {
                display: none;
                a {
                    background-color: $dark;
                    border: 1px solid white;
                    color: white;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 38px;
                    min-width: 190px;
                    max-height: 54px;
                    p{
                        white-space: nowrap;
                        max-width: 380px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    img {
                        margin-right: 10px;
                    }
                }
            }
            li {
                margin-right: 20px;
                a {
                    padding: 10px;
                    font-size: 0.8rem;
                    font-weight: bold;
                    cursor: pointer;
                }
                .active {
                    // border-bottom: 2px solid $primary;
                    color: white;
                }
                .cart {
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    min-height: 38px;
                    .cant{
                        background-color: red;
                        border-radius: 5px;
                        display: flex;
                        // width: 20px;
                        // height: 20px;
                        min-width: 20px;
                        align-items: center;
                        justify-content: center;
                        color: white;
                        // position: absolute;
                        // bottom: 22px;
                        margin-left: 10px;
                        padding: 2px;
                        font-weight: bold;
                    }
                    .letra {
                        display: none;
                    }
                }
                .cart_active {
                    text-decoration: underline;
                    background-color: white;
                    color: $dark;
                    border-radius: 5px;
                    display: flex;
                    align-items: end;
                    img {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

.tabmenu {
    position: fixed;
    bottom: 0px;
    background-color: $primary;
    height: 60px;
    width: 100%;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.15);
    .tab-content {
        height: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: auto;
        grid-gap: 0.1rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
        .tab {
            display: grid;
            align-items: center;
            justify-items: center;
            font-size: 0.8rem;
            opacity: 0.6;
            span {
                color: white;
            }
        }

        .active {
            opacity: 1;
            font-weight: bold;
        }
    }
}

.menu {
    position: fixed;   
    background: white;
    right: 0px;
    top:  83px;
    left: 0px;
    bottom: 0px;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transform: translateX(210px);
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.15);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    ul {
        margin-top: 10px;
        padding: 15px;
        li {
            padding: 15px;
            border-bottom: 1px solid #ccc;
            a {
                cursor: pointer;
                display: flex;
                align-items: center;
                font-size: 0.8rem;
                font-weight: bold;
                img {
                    margin-right: 20px;
                }
            }
        }
        li:hover {
            background-color: $gray;
            color: $primary;
        }
    }
}

.section-header{
    width: 100%;
    padding-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    a{
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            height: 50px;
        }
    }
    
}


.section-banner{
    width: 100%;
    //padding-top: 210px;
    img {
        width: 100%;
    }
}

.show-menu {
    opacity: 1;
    visibility: visible;
    transform: translateX(0px);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}


.content {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 10px;
    padding-top: 60px;
}

@media only screen and (min-width: $device-sm) {
    .navbar {
        // display: flex;
        display: flex;
        align-items: center;
        justify-content: center;
        .navbar-header {
            display: flex;
            width: 100%;
            align-items: center;
            
        }
        .nav-header {
            display: inline-block !important;
            .nav-content {
                .nav-logo {
                    img {
                        height: 40px;
                        width: auto;
                    }
                }
            }
        }
        .input-search{
            display: inline-block;
        }
        .cart {
            // min-width: 80px;
            // img {
            //     margin-right: 10px;
            // }
            .letra {
                display: flex !important;
            }
        }

        .navmenu {
            position: initial;
            display: flex !important;
        }
    }

    .navcarrito{
        .navmenu {
            width: 100%;
            justify-content: flex-end;
        }
    }

    .principal {
        justify-content: left;
    }


    .section-header{
        padding-top: 60px;
    }

    .section-banner{
        //padding-top: 140px;
        padding-top: 70px;
    }

    .pedido-list {
        padding: 150px 40px !important;
    }

    .categoria {
        top: 83px;
    }
}

@media only screen and (min-width: $device-md) {
    .principal {
        justify-content: left;
    }
    .navbar {
        .nav-header {
            padding: 0px 0px;
            .nav-content {
                display: flex;
                align-items: center;
                justify-content: center;
                .nav-logo {
                    padding-left: 40px;
                    img {
                        height: 60px;
                        width: auto;
                    }
                }
            }
        }
        .input-search{
            width: 50%;
        }
        .navmenu {
            position: initial;
            display: flex !important;
            ul {
                .profile {
                    display: flex;
                }
                .location {
                    p{
                        max-width: 100px;
                        text-overflow: ellipsis;
                    }
                    span{
                        display: none;
                    }
                    img{
                        display: flex;
                    }
                }
            }
        }
        
    }

    .icomenu {
        display: none !important;
    }

    .menu {
        left: initial;
        width: 225px;
    }

    .tabmenu {
        display: none;
    }

}

@media only screen and (min-width: $device-mv) {
    
    .navbar {
        .navmenu {
            ul {
                .location {
                    p{
                        max-width: 100%;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        
    }

}